import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  bigIntToFloat,
  getPrice,
  dateFullTime,
  bigIntToPercent,
  normalizeDate,
  handleParseNumber,
  toFixed
} from 'utils/helpers/converters';

import { useReferrals } from 'contexts/referrals/referrals.context';
import useCopyToClipboard from 'utils/hooks/use-copy-to-clipboard';

import Checkbox from 'components/common/checkbox/checkbox';
import Tooltip from 'components/common/tooltip/tooltip';

import CopyIcon from 'components/icons/CopyIcon';
import CaretDownIcon from 'components/icons/CaretDownIcon';
import { truncateString } from 'utils';

import {
  StyledActionButton,
  StyledFlex,
  StyledHidden,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  StyledTableTitle
} from './table.style';

export const TableHeadSettings = ({ selectedItems, handler }) => {
  const { t } = useTranslation();

  return (
    <StyledTableHead>
      <StyledTableRow className="style-settings">
        <StyledTableCell>
          <StyledTableTitle
            onClick={handler}
            className={cx('table-title-small', {
              disabled: typeof handler === 'undefined'
            })}
          >
            {selectedItems.length > 0
              ? t('pageReferrals.table.clearAll')
              : t('pageReferrals.table.chooseAll')}
          </StyledTableTitle>
        </StyledTableCell>
        <StyledTableCell>
          <StyledTableTitle>{t('pageReferrals.table.name')}</StyledTableTitle>
        </StyledTableCell>
        <StyledTableCell>
          <StyledTableTitle>{t('pageReferrals.table.coin')}</StyledTableTitle>
        </StyledTableCell>
        <StyledTableCell>
          <StyledTableTitle>
            {t('pageReferrals.table.referral')}
          </StyledTableTitle>
        </StyledTableCell>
        <StyledTableCell>
          <StyledTableTitle>{t('pageReferrals.table.show')}</StyledTableTitle>
        </StyledTableCell>
        <StyledTableCell>
          <StyledTableTitle>{t('pageReferrals.table.time')}</StyledTableTitle>
        </StyledTableCell>
      </StyledTableRow>
    </StyledTableHead>
  );
};

export const TableHeadStatistics = ({
  columns,
  requestSort,
  sortConfig,
  variant = 'style-1'
}) => {
  const { t } = useTranslation();

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  return (
    <StyledTableHead>
      <StyledTableRow className={variant}>
        {columns.map(({ title, dataIndex, sortable = true }) => {
          return (
            <StyledTableCell key={dataIndex}>
              <div
                className={cx(
                  'table-column-sorters',
                  getClassNamesFor(dataIndex),
                  {
                    'has-sortable': sortable
                  }
                )}
                role="presentation"
                onClick={sortable ? () => requestSort(dataIndex) : undefined}
              >
                <StyledTableTitle className="table-column-title">
                  {t(title)}
                </StyledTableTitle>
                {sortable && (
                  <span className="table-column-sorter">
                    <span className="table-caret">
                      <CaretDownIcon />
                    </span>
                  </span>
                )}
              </div>
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    </StyledTableHead>
  );
};

export const TableRowSettings = ({
  item,
  variant = 'style-settings',
  activeCoin,
  selected
}) => {
  const { t } = useTranslation();
  const [copied, copy] = useCopyToClipboard(400);
  const { action, selectedItemsNames, selectOneName } = useReferrals();

  return (
    <StyledTableRow className={cx(variant, { selected })}>
      <StyledTableCell>
        <Checkbox
          className="table-checkbox"
          disabled={action === 'add'}
          checked={selectedItemsNames.some((name) => name.value === item?.id)}
          onChange={() => selectOneName({ label: item?.name, value: item?.id })}
        />
      </StyledTableCell>
      <StyledTableCell className="table-cell-name">
        <StyledFlex>
          <StyledHidden>{truncateString(item?.name, 20)}</StyledHidden>
          <StyledActionButton onClick={() => copy(item?.name)}>
            {copied ? (
              <Tooltip message={t('walletsBlock.tooltipText')}>
                <CopyIcon />
              </Tooltip>
            ) : (
              <CopyIcon />
            )}
          </StyledActionButton>
        </StyledFlex>
      </StyledTableCell>
      <StyledTableCell className="table-cell-bold">
        {activeCoin?.tag.toUpperCase()}
      </StyledTableCell>
      <StyledTableCell className="table-cell-referral">
        {item?.percent}
      </StyledTableCell>
      <StyledTableCell>{item?.showPercent}</StyledTableCell>
      <StyledTableCell>{normalizeDate(item?.dateAdded)}</StyledTableCell>
    </StyledTableRow>
  );
};

export const TableRowStatistics = ({
  item,
  variant = 'style-stat',
  activeCoin,
  selected
}) => {
  const { t } = useTranslation();
  const [copied, copy] = useCopyToClipboard(400);
  const parseNumber = handleParseNumber(Number(item.hashRate));
  return (
    <StyledTableRow className={cx(variant, { selected })}>
      <StyledTableCell className="table-cell-name">
        <StyledFlex>
          <StyledHidden>{truncateString(item.userName, 30)}</StyledHidden>
          <StyledActionButton onClick={() => copy(item.userName)}>
            {copied ? (
              <Tooltip message={t('walletsBlock.tooltipText')}>
                <CopyIcon />
              </Tooltip>
            ) : (
              <CopyIcon />
            )}
          </StyledActionButton>
        </StyledFlex>
      </StyledTableCell>
      <StyledTableCell className="table-cell-bold">
        {activeCoin?.tag.toUpperCase()}
      </StyledTableCell>
      <StyledTableCell>
        {getPrice(bigIntToFloat(item.reward), item.prices)} $ /{' '}
        {toFixed(bigIntToFloat(item.reward))} {activeCoin?.tag.toUpperCase()}
      </StyledTableCell>
      <StyledTableCell>{`${parseNumber.val} ${parseNumber.metric}`}</StyledTableCell>
    </StyledTableRow>
  );
};

export const TableRowActivity = ({
  item,
  variant = 'style-activity',
  activeCoin,
  selected
}) => {
  const { t } = useTranslation();
  const [copied, copy] = useCopyToClipboard(400);
  return (
    <StyledTableRow className={cx(variant, { selected })}>
      <StyledTableCell className="table-cell-name">
        <StyledFlex>
          <StyledHidden>{truncateString(item.name, 30)}</StyledHidden>
          <StyledActionButton onClick={() => copy(item.name)}>
            {copied ? (
              <Tooltip message={t('walletsBlock.tooltipText')}>
                <CopyIcon />
              </Tooltip>
            ) : (
              <CopyIcon />
            )}
          </StyledActionButton>
        </StyledFlex>
      </StyledTableCell>
      <StyledTableCell className="table-cell-bold">
        {activeCoin?.tag.toUpperCase()}
      </StyledTableCell>
      <StyledTableCell>{item?.activeWorkers}</StyledTableCell>
      <StyledTableCell>{item?.inactiveWorkers}</StyledTableCell>
      <StyledTableCell>{dateFullTime(item?.lastShare)}</StyledTableCell>
      <StyledTableCell
        className={cx({
          'table-cell-error': !item.active
        })}
      >
        {item?.active
          ? t('pageReferrals.table.active')
          : t('pageReferrals.table.inactive')}
      </StyledTableCell>
    </StyledTableRow>
  );
};
