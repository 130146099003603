import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { GET_REFERRAL_STATISTICS_TOTAL } from 'graphql/referee.query';
import { useReferrals } from 'contexts/referrals/referrals.context';
import Heading from 'components/common/heading/heading';
import CoinListItem from './coin-list-item';

import { CoinList } from './coin-list.style';

const CoinListStatistics = () => {
  const { t } = useTranslation();
  const { activeCoin } = useReferrals();
  const { data, loading } = useQuery(GET_REFERRAL_STATISTICS_TOTAL, {
    context: {
      clientName: 'private'
    }
  });

  if (loading && data?.referralStatisticsTotal.length === 0) return null;

  return (
    <>
      <Heading mb={4} variant="h3">
        <span>{t('pageReferrals.title')}</span>
        <i>
          {data?.referralStatisticsTotal.find(
            (item) => item?.coinId === activeCoin?.id
          )?.totalUsers || 0}
        </i>
      </Heading>
      <CoinList>
        {data?.referralStatisticsTotal &&
          !loading &&
          data?.referralStatisticsTotal.map((item) => (
            <CoinListItem key={item?.coinId} {...item} />
          ))}
      </CoinList>
    </>
  );
};

export default CoinListStatistics;
