import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledTabItem = styled.li`
  a {
    display: block;
    padding: 16px 52px;
    border-radius: ${themeGet('radii.small')};
    font-weight: ${themeGet('fontWeights.button')};
    font-size: ${themeGet('fontSizes.base')}px;
    color: ${themeGet('colors.text.default')};
    background-color: 'transparent';
    box-shadow: none;
    text-transform: none;
    transition: all 0.3s ease;
    line-height: 1.5;
    white-space: nowrap;

    @media (max-width: 991px) {
      padding: 14px 16px;
    }

    &.active {
      color: #fff;
      background-color: ${themeGet('colors.primary.regular')};
      box-shadow: ${themeGet('shadows.button')};
    }
  }
`;

export const StyledTabsList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
`;

export const StyledTabsWrapper = styled.div`
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  &.style-2 {
    &:not(:last-child) {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    ${StyledTabsList} {
      ${StyledTabItem} {
        &:not(:first-child) {
          margin-left: 30px;
        }

        a {
          padding: 14px;
        }
      }
    }
  }
`;
