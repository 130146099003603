import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { referralsOptions } from 'utils/constant';
import { useCoins } from 'contexts/coins.context';
import { useReferrals } from 'contexts/referrals/referrals.context';
import Dropdown from 'components/common/form/dropdown/dropdown';

import { StyledFormWrapper } from './form.style';

const FormActivity = () => {
  const { t } = useTranslation();
  const [optionsCoins, setOptionsCoins] = useState(null);
  const { filters, activeCoin, setActiveCoin, setFilters } = useReferrals();
  const { coins } = useCoins();
  const { active } = filters;

  useEffect(() => {
    if (coins.length > 0 && optionsCoins === null) {
      setOptionsCoins(
        coins.map((item) => ({ value: item.id, label: item.tag.toUpperCase() }))
      );
    }
  }, [coins, optionsCoins]);

  const handleChangeCoin = ({ target }) => {
    setActiveCoin(coins.find((c) => c.id === target.value));
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilters({ [name]: value });
  };

  return (
    <StyledFormWrapper className="mode-activity" noValidate>
      {optionsCoins !== null && (
        <Dropdown
          name="activeCoin"
          value={activeCoin?.id}
          onChange={handleChangeCoin}
          options={optionsCoins}
        />
      )}
      <Dropdown
        name="active"
        value={active}
        label={t('dropdownOptions.referrals.all')}
        onChange={handleChange}
        options={referralsOptions}
      />
    </StyledFormWrapper>
  );
};

export default memo(FormActivity);
