import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import ReferralTable from 'components/referrals/statistics/table/table-settings';
import CoinListStatistics from 'components/referrals/coin-list/coin-list';
import FormActions from 'components/referrals/statistics/form/form-actions';
import ReferralActions from 'components/referrals/statistics/actions/actions';
import PageTabs from 'components/page-tabs/page-tabs';
import { ReferralsProvider } from 'contexts/referrals/referrals.context';
import { REFERRALS_TABS_ITEMS } from 'site-settings/site-navigation';

import {
  MainContentArea,
  Container,
  Row,
  Section
} from 'assets/styles/pages.style';

export default function ReferallsPage() {
  return (
    <ReferralsProvider>
      <MainContentArea>
        <Container>
          <CoinListStatistics />
          <PageTabs
            mode="referral"
            className="style-2"
            items={REFERRALS_TABS_ITEMS}
          />
          <Section mb={[4]}>
            <FormActions />
            <ReferralActions />
          </Section>
          <Row px={[0, 0, 4]}>
            <ReferralTable />
          </Row>
        </Container>
      </MainContentArea>
    </ReferralsProvider>
  );
}
