import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_REFEREE_INFO, GET_REFEREE_SETTINGS } from 'graphql/referee.query';
import { useReferrals } from 'contexts/referrals/referrals.context';
import useDebounce from 'utils/hooks/use-debounce';

const useLoadMoreReferrals = (name, activeCoin, pageSize = 10) => {
  const { setData, filters } = useReferrals();
  const { active, userName } = filters;

  const [hasMoreValues, setHasMoreValues] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, toggleLoading] = useState(false);
  const debouncedUserName = useDebounce(userName, 600);

  const [getReferee, { data, fetchMore }] = useLazyQuery(
    name === 'refereeInfo' ? GET_REFEREE_INFO : GET_REFEREE_SETTINGS,
    {
      context: { clientName: 'private' },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    (async function () {
      if (activeCoin?.id) {
        setLoading(true);
        const variables = {
          coinId: activeCoin?.id,
          pageNumber: 1,
          pageSize
        };
        if (active !== '') {
          variables.active = active;
        }
        if (debouncedUserName !== '') {
          variables.userName = debouncedUserName.replace(/ /g, '').split(',');
        }
        await getReferee({
          variables
        });
        setLoading(false);
      }
    })();
  }, [activeCoin?.id, debouncedUserName, active, getReferee]);

  useEffect(() => {
    if (!loading && data?.[name]) {
      setData(data?.[name]);
    }
  }, [data?.[name], loading]);

  const handleLoadMore = async () => {
    toggleLoading(true);
    setPageNumber((p) => p + 1);
    await fetchMore({
      variables: {
        coinId: activeCoin?.id,
        pageNumber: pageNumber + 1,
        pageSize
      },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        toggleLoading(false);

        if (fetchMoreResult?.[name].length === 0) {
          setHasMoreValues(false);
          return prev;
        }

        if (!fetchMoreResult) return prev;

        return {
          [name]: [...prev?.[name], ...fetchMoreResult?.[name]]
        };
      }
    });
  };

  return {
    data,
    dataLoading: loading,
    handleLoadMore,
    hasMoreValues,
    loadingMore
  };
};

export default useLoadMoreReferrals;
