import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useLoadMoreReferrals from 'utils/hooks/use-load-more-referrals';
import { useReferrals } from 'contexts/referrals/referrals.context';
import { useScrollbar } from 'utils/hooks/use-scrollbar';
import { useWindowSize } from 'utils/hooks/use-window-size';
import { useCoins } from 'contexts/coins.context';
import Loader from 'components/common/loader/loader';

import LoadMoreButton from './load-more-button';
import { TableHeadSettings, TableRowSettings } from './table-elements';

import {
  StyledEmpty,
  StyledOverflow,
  StyledTable,
  StyledTableBody,
  StyledTableWrapper
} from './table.style';

const RefferalTable = () => {
  const { t } = useTranslation();
  const overflowWrapperRef = useRef(null);
  const { isMobile } = useWindowSize();
  const {
    action,
    users,
    activeCoin,
    setActiveCoin,
    selectedItemsNames,
    setSelectedItemsNames,
    selectAllNames
  } = useReferrals();

  const { loading, coins } = useCoins();

  const { handleLoadMore, hasMoreValues, dataLoading, loadingMore } =
    useLoadMoreReferrals('refereeSettings', activeCoin, 300);

  useEffect(() => {
    if (!loading && coins.length > 0) {
      setActiveCoin(coins[0]);
    }
  }, [loading, coins]);

  useScrollbar(overflowWrapperRef, isMobile);

  if (dataLoading) {
    return <Loader width="30px" height="30px" />;
  }

  if (users.length === 0) {
    return (
      <StyledEmpty>
        <p>{t('pageReferrals.table.empty')}</p>
      </StyledEmpty>
    );
  }

  return (
    <StyledTableWrapper>
      <StyledOverflow ref={overflowWrapperRef}>
        <StyledTable>
          <TableHeadSettings
            selectedItems={selectedItemsNames}
            handler={
              action === 'add'
                ? undefined
                : selectedItemsNames.length === 0
                ? () => selectAllNames()
                : () => setSelectedItemsNames([])
            }
          />
          <StyledTableBody>
            {users.map((item) => (
              <TableRowSettings
                key={item?.id}
                item={item}
                activeCoin={activeCoin}
                selected={selectedItemsNames.some(
                  (name) => name.value === item?.id
                )}
              />
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledOverflow>
      {/* <LoadMoreButton */}
      {/*  items={users} */}
      {/*  hasMore={hasMoreValues} */}
      {/*  loading={loadingMore} */}
      {/*  onClick={handleLoadMore} */}
      {/* > */}
      {/*  {t('transactionsBlock.moreButtonText')} */}
      {/* </LoadMoreButton> */}
    </StyledTableWrapper>
  );
};

export default RefferalTable;
