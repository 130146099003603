import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { useMutation } from '@apollo/client';

import { useReferrals } from 'contexts/referrals/referrals.context';
import { UPDATE_REFEREE } from 'graphql/referee.query';

import { getFieldProps } from 'utils';
import { percentToBigInt } from 'utils/helpers/converters';
import { useNotify } from 'contexts/notification.context';
import { Input } from 'components/common/form/input';
import { Button } from 'components/common/button/button';

import {
  StyledElement,
  StyledForm,
  StyledFormWrapper,
  StyledLabel
} from '../actions.style';

import DataAction from '../data-action';
import customStyles from '../custom-styles';

const ChangeForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setMessage } = useNotify();
  const {
    users,
    activeCoin,
    updateUsers,
    selectedItemsNames,
    setSelectedItemsNames
  } = useReferrals();

  const [updateReferee, { loading }] = useMutation(UPDATE_REFEREE);

  const submitHandler = async (
    values,
    { touched, validateForm, resetForm, setTouched, setSubmitting }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      try {
        const result = await updateReferee({
          variables: {
            coinId: activeCoin.id,
            input: values.users.map((item) => ({
              refereeId: item.value,
              percent: percentToBigInt(values.percent),
              showPercent: percentToBigInt(values.showPercent)
            }))
          },
          context: { clientName: 'private' }
        });
        if (result?.data?.updateReferee) {
          await updateUsers(values);
          setMessage('pageReferrals.notifications.updateRefereeSuccess', {
            status: 'success'
          });
        }
      } catch (err) {
        resetForm();
      }
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <StyledFormWrapper>
      <Formik
        validateOnBlur
        initialValues={{
          users: [],
          percent: '',
          showPercent: ''
        }}
        validationSchema={yup.object().shape({
          users: yup
            .array()
            .min(1)
            .of(
              yup.object().shape({
                label: yup.string(),
                value: yup.string()
              })
            )
            .required(t('pageReferrals.formFields.userRequired')),
          percent: yup
            .number()
            .required(t('pageReferrals.formFields.amountRequired'))
            .min(1, t('pageReferrals.formFields.amountMin'))
            .max(100, t('pageReferrals.formFields.amountMax')),
          showPercent: yup
            .number()
            .required(t('pageReferrals.formFields.amountRequired'))
            .min(1, t('pageReferrals.formFields.amountMin'))
            .max(100, t('pageReferrals.formFields.amountMax'))
        })}
        onSubmit={submitHandler}
      >
        {(formik) => (
          <Form noValidate>
            <DataAction items={selectedItemsNames}>
              <StyledForm className="grid-mode">
                <StyledElement>
                  <StyledLabel>
                    {t('pageReferrals.table.name')}
                    <span>{t('pageReferrals.actions.change.help')}</span>
                  </StyledLabel>
                  <Select
                    isMulti
                    required
                    styles={customStyles(theme)}
                    name="users"
                    onChange={(selectedOption) => {
                      setSelectedItemsNames(selectedOption);
                    }}
                    value={selectedItemsNames}
                    options={users.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))}
                  />
                </StyledElement>
                <StyledElement>
                  <StyledLabel>
                    {t('pageReferrals.actions.change.text')}
                  </StyledLabel>
                  <Input type="text" {...getFieldProps(formik, 'percent')} />
                </StyledElement>
                <StyledElement>
                  <StyledLabel>
                    {t('pageReferrals.actions.change.exp')}
                  </StyledLabel>
                  <Input
                    type="text"
                    {...getFieldProps(formik, 'showPercent')}
                  />
                </StyledElement>
                <StyledElement>
                  <Button
                    size="middle"
                    variant="success"
                    loading={loading}
                    disabled={!formik.isValid}
                    type="submit"
                  >
                    {t('pageReferrals.actions.change.buttonText')}
                  </Button>
                </StyledElement>
              </StyledForm>
            </DataAction>
          </Form>
        )}
      </Formik>
    </StyledFormWrapper>
  );
};

export default ChangeForm;
