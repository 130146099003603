import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useLazyQuery } from '@apollo/client';

import Loader from 'components/common/loader/loader';

import useSortableData from 'utils/hooks/use-sortable-data';
import useDebounce from 'utils/hooks/use-debounce';
import { columnsReferralsStatistics } from 'utils/columns-table';
import { GET_REFERRALS_STATISTICS_BY_DAY } from 'graphql/referee.query';
import { ISOString } from 'utils/custom-static-ranges';
import { useScrollbar } from 'utils/hooks/use-scrollbar';
import { useWindowSize } from 'utils/hooks/use-window-size';
import { useReferrals } from 'contexts/referrals/referrals.context';
import { useCoins } from 'contexts/coins.context';

import { TableHeadStatistics, TableRowStatistics } from './table-elements';

import {
  StyledTable,
  StyledTableWrapper,
  StyledTableBody,
  StyledEmpty,
  StyledOverflow
} from './table.style';

const RefferalTable = () => {
  const { t } = useTranslation();
  const overflowWrapperRef = useRef(null);
  const { isMobile } = useWindowSize();
  const {
    setData,
    activeStateRange,
    toggleDateRange,
    activeCoin,
    setActiveCoin,
    filters,
    users
  } = useReferrals();

  const { date, userName } = filters;
  const { loading, coins } = useCoins();
  const debouncedUserName = useDebounce(userName, 600);

  const [getReferralStatisticsByDay, { data, loading: dataLoading }] =
    useLazyQuery(GET_REFERRALS_STATISTICS_BY_DAY, {
      context: {
        clientName: 'private'
      }
    });

  useEffect(() => {
    if (!loading && coins.length > 0) {
      setActiveCoin(coins[0]);
    }
  }, [loading, coins]);

  useEffect(() => {
    toggleDateRange(date);
  }, [date]);

  useEffect(() => {
    if (activeCoin?.id) {
      getReferralStatisticsByDay({
        variables: {
          coinId: activeCoin?.id,
          userName:
            debouncedUserName !== ''
              ? debouncedUserName.replace(/ /g, '').split(',')
              : [],
          fromDate: format(activeStateRange.startDate, ISOString),
          toDate: format(activeStateRange.endDate, ISOString)
        }
      });
    }
  }, [
    activeCoin?.id,
    debouncedUserName,
    activeStateRange.startDate,
    activeStateRange.endDate
  ]);

  useEffect(() => {
    if (!dataLoading && data?.referralStatisticsByDay) {
      setData(data?.referralStatisticsByDay);
    }
  }, [data?.referralStatisticsByDay, dataLoading]);

  useScrollbar(overflowWrapperRef, isMobile);

  const { items, requestSort, sortConfig } = useSortableData(users);

  if (dataLoading) {
    return <Loader width="30px" height="30px" />;
  }

  if (items.length === 0) {
    return (
      <StyledEmpty>
        <p>{t('pageReferrals.table.empty')}</p>
      </StyledEmpty>
    );
  }

  return (
    <StyledTableWrapper>
      <StyledOverflow ref={overflowWrapperRef}>
        <StyledTable>
          <TableHeadStatistics
            columns={columnsReferralsStatistics}
            variant="style-stat"
            requestSort={requestSort}
            sortConfig={sortConfig}
          />
          <StyledTableBody>
            {items.map((item) => {
              return (
                <TableRowStatistics
                  key={`${item?.userId}${item?.reward}${item?.hashRate}`}
                  item={item}
                  activeCoin={activeCoin}
                />
              );
            })}
          </StyledTableBody>
        </StyledTable>
      </StyledOverflow>
    </StyledTableWrapper>
  );
};

export default RefferalTable;
