import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import Heading from 'components/common/heading/heading';
import ReferralTable from 'components/referrals/statistics/table/table-statistics';
import CoinListStatistics from 'components/referrals/coin-list/coin-list';
import ReferralForm from 'components/referrals/statistics/form/form';
import { useUser } from 'contexts/user.context';
import { ReferralsProvider } from 'contexts/referrals/referrals.context';
import { REFERRALS_TABS_ITEMS } from 'site-settings/site-navigation';

import {
  MainContentArea,
  Container,
  Row,
  Section
} from 'assets/styles/pages.style';
import PageTabs from '../page-tabs/page-tabs';

export default function ReferallsPage() {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <ReferralsProvider>
      <MainContentArea>
        <Container>
          <CoinListStatistics />
          <PageTabs
            mode="referral"
            className="style-2"
            items={REFERRALS_TABS_ITEMS}
          />
          <Section mb={[4]}>
            {!user?.referral?.manager && (
              <Heading mb={4} variant="h3">
                <span>{t('pageReferrals.tabsItems.statistics')}</span>
              </Heading>
            )}
            <ReferralForm />
          </Section>
          <Row px={[0, 0, 4]}>
            <ReferralTable />
          </Row>
        </Container>
      </MainContentArea>
    </ReferralsProvider>
  );
}
