import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { dateOptions } from 'utils/constant';
import { useCoins } from 'contexts/coins.context';
import { useReferrals } from 'contexts/referrals/referrals.context';
import Dropdown from 'components/common/form/dropdown/dropdown';
import { Input } from 'components/common/form/input';

import {
  sumHelper,
  bigIntToFloat,
  toFixed,
  getPrice
} from 'utils/helpers/converters';

import {
  StyledFormWrapper,
  StyledData,
  StyledText,
  StyledRewardData
} from './form.style';

const Form = () => {
  const { t } = useTranslation();
  const [reward, setReward] = useState('0$ / 0 ETH');
  const [optionsCoins, setOptionsCoins] = useState(null);
  const { users, filters, activeCoin, setActiveCoin, setFilters } =
    useReferrals();
  const { coins } = useCoins();
  const { date, userName } = filters;

  useEffect(() => {
    if (coins.length > 0 && optionsCoins === null) {
      setOptionsCoins(
        coins.map((item) => ({ value: item.id, label: item.tag.toUpperCase() }))
      );
    }
  }, [coins, optionsCoins]);

  useEffect(() => {
    if (users.length > 0) {
      const result = bigIntToFloat(sumHelper(users));
      setReward(
        `${getPrice(result, users[0]?.prices)}$ / ${toFixed(
          result
        )} ${activeCoin?.tag.toUpperCase()}`
      );
    }
  }, [activeCoin?.tag, users]);

  const handleChangeCoin = ({ target }) => {
    setActiveCoin(coins.find((c) => c.id === target.value));
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilters({ [name]: value });
  };

  return (
    <StyledFormWrapper noValidate>
      {optionsCoins !== null && (
        <Dropdown
          name="activeCoin"
          value={activeCoin?.id}
          onChange={handleChangeCoin}
          options={optionsCoins}
        />
      )}
      <Dropdown
        name="date"
        value={date}
        onChange={handleChange}
        options={dateOptions}
      />
      <Input
        type="text"
        name="userName"
        onChange={handleChange}
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
        placeholder={t('pageReferrals.searchPlaceholder')}
        value={userName}
      />
      <StyledData>
        <StyledText>{t('pageReferrals.rewardText')}</StyledText>
        <StyledRewardData>{reward}</StyledRewardData>
      </StyledData>
    </StyledFormWrapper>
  );
};

export default memo(Form);
