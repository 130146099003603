import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledTableWrapper = styled.div`
  position: relative;
`;

export const StyledEmpty = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px 40px;

  p {
    color: ${themeGet('colors.text.regular')};
    font-size: ${themeGet('fontSizes.base')}px;
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const StyledOverflow = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  transform: rotateX(180deg);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  > * {
    transform: rotateX(180deg);
  }
`;

export const StyledTable = styled.div`
  @media ${themeGet('mediaQueries.mobileOnly')} {
    padding-top: 20px;
  }
`;

export const StyledTableCell = styled.div`
  line-height: 17px;
  font-size: ${themeGet('fontSizes.xs')}px;

  @media ${themeGet('mediaQueries.bigDesktopUp')} {
    font-size: ${themeGet('fontSizes.sm')}px;
  }

  &.table-cell-bold {
    font-weight: ${themeGet('fontWeights.bold')};
  }

  &.table-cell-error {
    color: ${themeGet('colors.alerts.error')};
  }

  .table-checkbox {
    margin-left: 10px;
  }

  span {
    color: ${themeGet('colors.text.regular')};
  }

  .table-column-sorters {
    display: flex;
    align-items: center;

    .table-caret {
      line-height: 0;
      color: ${themeGet('colors.text.regular')};
    }

    &.ascending,
    &.descending {
      .table-caret {
        color: ${themeGet('colors.primary.regular')};
      }
    }

    &.ascending {
      .table-caret {
        transform: rotate(180deg);
      }
    }

    .table-column-sorter {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      line-height: 1;
    }

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  .has-sortable {
    cursor: pointer;
  }
`;

export const StyledTableRow = styled.div`
  display: grid;

  &.style-settings {
    grid-template-columns:
      minmax(120px, 3%) minmax(150px, 42%) minmax(150px, 15%) minmax(150px, 15%)
      minmax(150px, 15%)
      minmax(150px, 10%);
  }

  &.style-stat {
    grid-template-columns:
      minmax(150px, 36%) minmax(150px, 17%) minmax(150px, 25%)
      minmax(150px, 22%);

    @media ${themeGet('mediaQueries.mobileOnly')} {
      grid-template-columns:
        minmax(250px, 39%) minmax(100px, 11%) minmax(180px, 26%)
        minmax(150px, 24%);
    }
  }

  &.style-activity {
    grid-template-columns:
      minmax(150px, 27%) minmax(150px, 7%) minmax(150px, 17%)
      minmax(150px, 17%) minmax(150px, 18%) minmax(150px, 14%);
  }

  &.selected {
    ${StyledTableCell} {
      &.table-cell-name,
      &.table-cell-referral {
        color: ${themeGet('colors.primary.regular')};
        font-weight: ${themeGet('fontWeights.bold')};
      }
    }
  }
`;

export const StyledTableTitle = styled.span`
  text-transform: uppercase;
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.primary.regular')};

  &.disabled {
    pointer-events: none;
  }
`;

export const StyledTableHead = styled.div`
  ${StyledTableCell} {
    padding: 0 20px 20px;
  }

  ${StyledTableTitle} {
    color: ${themeGet('colors.primary.regular')};

    &.table-title-small {
      font-size: 11px;
      cursor: pointer;
      transition: all 0.25s ease;
      color: ${themeGet('colors.text.regular')};

      &:hover {
        color: ${themeGet('colors.primary.regular')};
      }
    }
  }
`;

export const StyledTableBody = styled.div`
  ${StyledTableRow} {
    &:nth-child(odd) {
      ${StyledTableCell} {
        background-color: ${themeGet('colors.primary.light')};

        &:first-child {
          border-top-left-radius: ${themeGet('radii.base')};
          border-bottom-left-radius: ${themeGet('radii.base')};
        }

        &:last-child {
          border-top-right-radius: ${themeGet('radii.base')};
          border-bottom-right-radius: ${themeGet('radii.base')};
        }
      }
    }
  }

  ${StyledTableCell} {
    padding: 18px 20px;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: 85% 1fr;
  }

  > button {
    margin-left: 7px;
  }
`;

export const StyledHidden = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledActionButton = styled.button`
  padding: 0;
  background-color: transparent;
  color: ${themeGet('colors.text.regular')};
  transition: ${themeGet('customs.transition')};

  &:hover {
    color: ${themeGet('colors.primary.regular')};
  }
`;
