import { createContext, useReducer, useMemo, useContext } from 'react';

import { lastWeek } from 'utils/custom-static-ranges';
import { referralsReducer } from './referrals.reducer';

const initialState = {
  action: 'add',
  activeCoin: null,
  selectedItemsNames: [],
  activeStateRange: lastWeek.range(),
  filters: {
    userName: '',
    active: '',
    date: 'lastWeek'
  },
  users: []
};

const ReferralsContext = createContext();

ReferralsContext.displayName = 'ReferralsContext';

export const ReferralsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(referralsReducer, initialState);

  const setData = (users) => dispatch({ type: 'SET_DATA', users });
  const setAction = (value) => dispatch({ type: 'SET_ACTION', value });
  const setSelectedItemsNames = (names) =>
    dispatch({
      type: 'SET_SELECTED_ITEMS_NAMES',
      names
    });
  const selectOneName = (item) => dispatch({ type: 'SELECT_ONE_NAME', item });
  const selectAllNames = () => dispatch({ type: 'SELECT_ALL_NAMES' });
  const setFilters = (filter) => dispatch({ type: 'SET_FILTERS', filter });
  const setActiveCoin = (coin) => dispatch({ type: 'SET_ACTIVE_COIN', coin });

  const updateUsers = (values) => dispatch({ type: 'UPDATE_DATA', values });
  const deleteUsers = (ids) => dispatch({ type: 'DELETE_DATA', ids });

  const toggleDateRange = (activeDateRange) =>
    dispatch({ type: 'SET_TOGGLE_DATE_RANGE', activeDateRange });

  const value = useMemo(
    () => ({
      ...state,
      setData,
      setAction,
      setFilters,
      setSelectedItemsNames,
      selectOneName,
      selectAllNames,
      updateUsers,
      deleteUsers,
      setActiveCoin,
      toggleDateRange
    }),
    [state]
  );

  return (
    <ReferralsContext.Provider value={value}>
      {children}
    </ReferralsContext.Provider>
  );
};

export const useReferrals = () => {
  const context = useContext(ReferralsContext);
  if (context === undefined) {
    throw new Error('useReferrals must be used within a ReferralsProvider');
  }
  return context;
};
