const customStyles = (theme) => ({
  placeholder: (base) => ({
    ...base,
    padding: '12px 0'
  }),
  input: (base) => ({
    ...base,
    color: theme?.colors?.body?.text
  }),
  indicatorsContainer: (base) => ({
    ...base,
    cursor: 'pointer',

    '> div': {
      color: theme?.colors?.text?.regular
    }
  }),
  clearIndicator: (base) => ({
    ...base,
    cursor: 'pointer'
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: theme?.colors?.text?.regular
  }),
  control: (base) => ({
    ...base,
    lineHeight: '1.5',
    fontSize: '16px',
    boxShadow: 'none',
    backgroundColor: theme?.colors?.input?.bg,
    borderRadius: theme?.radii?.base,
    padding: '5px',
    minHeight: '60px',
    border: '1px solid transparent',

    '&:hover': {
      borderColor: theme?.colors?.input?.border
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0
  }),
  multiValue: (base) => ({
    ...base,
    margin: '4px',
    backgroundColor: 'transparent',
    padding: '7px 8px',
    borderRadius: theme?.radii?.base,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme?.colors?.select?.borderColor
  }),
  multiValueLabel: () => ({
    fontSize: `${theme?.fontSizes.base}px`,
    color: 'inherit',
    padding: 0,
    borderRadius: 0,
    '@media (max-width: 767px)': {
      fontSize: '12px'
    }
  }),
  multiValueRemove: () => ({
    marginLeft: '10px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',

    svg: {
      width: '20px',
      height: '20px',
      transition: 'color .25s ease',
      color: theme?.colors?.select?.borderColor
    },

    '&:hover': {
      svg: {
        color: theme?.colors?.primary?.regular
      }
    }
  }),
  menu: (base) => ({
    ...base,
    marginTop: '4px',
    marginBottom: 0,
    boxShadow: 'none',
    backgroundColor: theme?.colors?.select?.menuBgColor,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme?.colors?.select?.menuBorderColor,
    borderRadius: theme?.radii?.base
  }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  option: (base, state) => ({
    ...base,
    cursor: 'pointer',
    color: state.isSelected
      ? theme.colors?.select.focusedTextColor
      : theme.colors?.text.default,
    backgroundColor: state.isFocused
      ? theme?.colors?.select?.focusedBgColor
      : 'transparent',

    '&:first-child': {
      borderTopLeftRadius: theme?.radii?.base,
      borderTopRightRadius: theme?.radii?.base
    },
    '&:last-child': {
      borderBottomLeftRadius: theme?.radii?.base,
      borderBottomRightRadius: theme?.radii?.base
    },

    '&:active': {
      color: '#666',
      backgroundColor: theme?.colors?.skeleton?.backgroundColor
    }
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.colors?.text.default
  })
});

export default customStyles;
