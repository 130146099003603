import { Router } from '@reach/router';
import { useTranslation } from 'react-i18next';

import PrivateRoute from 'components/private-route';
import ReferralsSettings from 'components/private/referral-settings';
import ReferralsStatistics from 'components/private/referral-statistics';
import ReferralsActivity from 'components/private/referral-activity';
import SEO from 'components/seo';

const ReferallsPage = () => {
  return (
    <Router basepath="/referrals">
      <PrivateRoute path="/settings" component={ReferralsSettings} />
      <PrivateRoute path="/statistics" component={ReferralsStatistics} />
      <PrivateRoute path="/activity" component={ReferralsActivity} />
    </Router>
  );
};

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageReferrals.title')} />;
};

export default ReferallsPage;
