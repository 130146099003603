import { useState, useEffect } from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';

import CheckedIcon from 'components/icons/CheckedIcon';
import { StyledToggle, StyledCheckbox } from './checkbox.style';

const Checkbox = ({
  className = '',
  disabled = false,
  checked = false,
  onChange
}) => {
  const [toggle, setToggle] = useState(checked);

  useEffect(() => {
    setToggle(checked);
  }, [checked]);

  const triggerToggle = () => {
    setToggle((prev) => !prev);

    if (isFunction(onChange)) {
      onChange(!toggle);
    }
  };

  return (
    <StyledToggle
      onClick={disabled ? undefined : triggerToggle}
      className={cx(className, {
        'toggle--checked': toggle,
        disabled
      })}
    >
      <StyledCheckbox>{toggle && <CheckedIcon />}</StyledCheckbox>
    </StyledToggle>
  );
};
export default Checkbox;
