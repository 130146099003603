import { useFormikContext } from 'formik';
import { memo, useEffect } from 'react';

const DataAction = ({ items, children }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('users', items);
  }, [items]);

  return children;
};

export default memo(DataAction);
