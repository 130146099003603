import { useTranslation } from 'react-i18next';

import { useCoins } from 'contexts/coins.context';
import { handleParseNumber } from 'utils/helpers/converters';

import { CoinItem, CoinTextItem, CoinDataItem } from './coin-list.style';

const CoinListItem = ({ coinId, hashRate }) => {
  const { t } = useTranslation();
  const { coins } = useCoins();
  const parseNumber = handleParseNumber(hashRate);
  const tag = coins.find((c) => c?.id === coinId)?.tag.toUpperCase();

  return (
    <CoinItem>
      <CoinTextItem>{t('pageReferrals.avgHashrateText')}</CoinTextItem>
      <CoinDataItem>
        <i>{tag}</i> {`${parseNumber?.val} ${parseNumber?.metric}`}
      </CoinDataItem>
    </CoinItem>
  );
};

export default CoinListItem;
