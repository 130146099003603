import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/common/loader/loader';

import useSortableData from 'utils/hooks/use-sortable-data';
import { columnsReferralsActivity } from 'utils/columns-table';
import { useScrollbar } from 'utils/hooks/use-scrollbar';
import { useWindowSize } from 'utils/hooks/use-window-size';
import { useReferrals } from 'contexts/referrals/referrals.context';
import { useCoins } from 'contexts/coins.context';
import useLoadMoreReferrals from 'utils/hooks/use-load-more-referrals';

import LoadMoreButton from './load-more-button';
import { TableHeadStatistics, TableRowActivity } from './table-elements';

import {
  StyledEmpty,
  StyledOverflow,
  StyledTable,
  StyledTableBody,
  StyledTableWrapper
} from './table.style';

const RefferalTableActivity = () => {
  const { t } = useTranslation();
  const overflowWrapperRef = useRef(null);
  const { isMobile } = useWindowSize();
  const { activeCoin, setActiveCoin, users } = useReferrals();
  const { loading, coins } = useCoins();

  const { handleLoadMore, hasMoreValues, dataLoading, loadingMore } =
    useLoadMoreReferrals('refereeInfo', activeCoin, 300);

  useEffect(() => {
    if (!loading && coins.length > 0) {
      setActiveCoin(coins[0]);
    }
  }, [loading, coins]);

  useScrollbar(overflowWrapperRef, isMobile);

  const { items, requestSort, sortConfig } = useSortableData(users);

  if (dataLoading) {
    return <Loader width="30px" height="30px" />;
  }

  if (items.length === 0) {
    return (
      <StyledEmpty>
        <p>{t('pageReferrals.table.empty')}</p>
      </StyledEmpty>
    );
  }

  return (
    <StyledTableWrapper>
      <StyledOverflow ref={overflowWrapperRef}>
        <StyledTable>
          <TableHeadStatistics
            columns={columnsReferralsActivity}
            variant="style-activity"
            requestSort={requestSort}
            sortConfig={sortConfig}
          />
          <StyledTableBody>
            {items.map((item) => {
              return (
                <TableRowActivity
                  key={`${item?.name}${item?.lastShare}`}
                  item={item}
                  activeCoin={activeCoin}
                />
              );
            })}
          </StyledTableBody>
        </StyledTable>
      </StyledOverflow>
      {/*<LoadMoreButton*/}
      {/*  items={items}*/}
      {/*  hasMore={hasMoreValues}*/}
      {/*  loading={loadingMore}*/}
      {/*  onClick={handleLoadMore}*/}
      {/*>*/}
      {/*  {t('transactionsBlock.moreButtonText')}*/}
      {/*</LoadMoreButton>*/}
    </StyledTableWrapper>
  );
};

export default RefferalTableActivity;
