import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCoins } from 'contexts/coins.context';
import { useReferrals } from 'contexts/referrals/referrals.context';
import { Input } from 'components/common/form/input';
import Dropdown from 'components/common/form/dropdown/dropdown';

import { StyledFormWrapper } from './form.style';

const FormActions = () => {
  const { t } = useTranslation();
  const [optionsCoins, setOptionsCoins] = useState(null);
  const { filters, activeCoin, setActiveCoin, setFilters } = useReferrals();
  const { coins } = useCoins();
  const { userName } = filters;

  useEffect(() => {
    if (coins.length > 0 && optionsCoins === null) {
      setOptionsCoins(
        coins.map((item) => ({ value: item.id, label: item.tag.toUpperCase() }))
      );
    }
  }, [coins, optionsCoins]);

  const handleChangeCoin = ({ target }) => {
    setActiveCoin(coins.find((c) => c.id === target.value));
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilters({ [name]: value });
  };

  return (
    <StyledFormWrapper className="mode-actions" noValidate>
      {optionsCoins !== null && (
        <Dropdown
          name="activeCoin"
          value={activeCoin?.id}
          onChange={handleChangeCoin}
          options={optionsCoins}
        />
      )}
      <Input
        type="text"
        name="userName"
        onChange={handleChange}
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
        placeholder={t('pageReferrals.searchPlaceholder')}
        value={userName}
      />
    </StyledFormWrapper>
  );
};

export default memo(FormActions);
