import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const CoinList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  @media ${themeGet('mediaQueries.mobileOnly')} {
    grid-template-columns: 1fr;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const CoinItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 20px;
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  @media (max-width: 991px) {
    padding: 20px;
  }
`;

export const CoinTextItem = styled.span`
  font-size: ${themeGet('fontSizes.sm')}px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const CoinDataItem = styled.h3`
  i {
    color: ${themeGet('colors.linkColor')};
    font-style: normal;
  }
`;
