import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledFormWrapper = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  align-items: center;

  @media ${themeGet('mediaQueries.bigDesktopUp')} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${themeGet('mediaQueries.mobileOnly')} {
    grid-template-columns: 1fr;
  }

  &.mode-actions {
    @media ${themeGet('mediaQueries.bigDesktopUp')} {
      grid-template-columns: 1fr 3fr;
    }
  }

  &.mode-activity {
    @media ${themeGet('mediaQueries.bigDesktopUp')} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledData = styled.div`
  @media (min-width: 576px) {
    padding-left: 25px;
  }
`;

export const StyledText = styled.span`
  margin-bottom: 4px;
  color: ${themeGet('colors.primary.regular')};
  font-size: ${themeGet('fontSizes.xs')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
`;
export const StyledRewardData = styled.h4`
  font-size: ${themeGet('fontSizes.mdUp')}px;
  font-weight: ${themeGet('fontWeights.bold')};
`;
