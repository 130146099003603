import { useUser } from 'contexts/user.context';
import PageTabButton from './page-tab-button';

import { StyledTabsWrapper, StyledTabsList } from './page-tabs.style';

const PageTabs = ({ mode = 'default', className = 'style-1', items }) => {
  const { user } = useUser();

  if (!items) return null;

  if (mode === 'referral' && !user?.referral?.manager) return null;

  return (
    <StyledTabsWrapper className={className}>
      <StyledTabsList>
        {items.map((item) => (
          <PageTabButton key={item.id} item={item} />
        ))}
      </StyledTabsList>
    </StyledTabsWrapper>
  );
};

export default PageTabs;
