import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border: 1px solid;
  border-color: ${themeGet('colors.text.regular')};
  border-radius: 2px;
  background-color: ${themeGet('colors.white')};
  transition: all 0.25s ease;
`;

export const StyledToggle = styled.div`
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  display: flex;
  align-items: flex-start;

  &:hover {
    ${StyledCheckbox} {
      border-color: ${themeGet('colors.primary.regular')};
    }
  }

  &.toggle--checked {
    ${StyledCheckbox} {
      border-color: ${themeGet('colors.primary.regular')};
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.35;
  }
`;
