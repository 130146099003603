import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { StyledTabItem } from './page-tabs.style';

const CustomLink = ({ children, ...props }) => (
  <Link
    getProps={({ location, href }) =>
      location.pathname === href ? { className: 'active' } : ''
    }
    {...props}
  >
    {children}
  </Link>
);

const PageTabButton = ({ item: { id, href } }) => {
  const { t } = useTranslation();
  return (
    <StyledTabItem>
      <CustomLink to={href}>{t(id)}</CustomLink>
    </StyledTabItem>
  );
};

export default PageTabButton;
