import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { CREATE_REFEREE } from 'graphql/referee.query';
import { Input } from 'components/common/form/input';
import { useNotify } from 'contexts/notification.context';
import { getFieldProps } from 'utils';
import { Button } from 'components/common/button/button';

import { StyledForm, StyledFormWrapper, StyledLabel } from '../actions.style';

const AddForm = () => {
  const { t } = useTranslation();
  const { setMessage } = useNotify();
  const [createReferee, { loading }] = useMutation(CREATE_REFEREE);

  const submitHandler = async (
    values,
    { touched, validateForm, resetForm, setTouched, setSubmitting }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      try {
        const result = await createReferee({
          variables: {
            refereeName: [values.username]
          },
          context: { clientName: 'private' }
        });
        if (result?.data?.createReferee) {
          setMessage('pageReferrals.notifications.createRefereeSuccess', {
            status: 'success'
          });
        }
        resetForm();
      } catch (err) {
        setMessage('pageReferrals.notifications.createRefereeError', {
          status: 'error'
        });
        resetForm();
      }
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <StyledFormWrapper>
      <StyledLabel>{t('pageReferrals.actions.add.text')}</StyledLabel>
      <Formik
        validateOnBlur
        initialValues={{
          username: ''
        }}
        validationSchema={yup.object().shape({
          username: yup
            .string()
            .required(t('pageReferrals.formFields.amountRequired'))
            .min(4, t('authForm.registerFields.usernameMin'))
            .max(42, t('authForm.registerFields.usernameMax'))
        })}
        onSubmit={submitHandler}
      >
        {(formik) => (
          <Form noValidate>
            <StyledForm className="flex-mode">
              <Input
                type="text"
                name="username"
                placeholder={t('pageReferrals.actions.add.placeholder')}
                {...getFieldProps(formik, 'username')}
              />
              <Button
                size="middle"
                variant="success"
                loading={loading}
                disabled={!formik.isValid}
                type="submit"
              >
                {t('pageReferrals.actions.add.buttonText')}
              </Button>
            </StyledForm>
          </Form>
        )}
      </Formik>
    </StyledFormWrapper>
  );
};

export default AddForm;
