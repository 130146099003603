import { gql } from '@apollo/client';

export const GET_REFEREES = gql`
  query (
    $coinId: ObjectID!
    $refereeName: [String!]
    $pageNumber: Uint64!
    $pageSize: Uint64!
  ) {
    referees(
      coinId: $coinId
      pageNumber: $pageNumber
      pageSize: $pageSize
      refereeName: $refereeName
    ) {
      id
      name
      percent
      showPercent
      dateAdded
      info {
        active
        hashRate
        lastShare
      }
    }
  }
`;

export const GET_REFERRALS_STATISTICS_BY_DAY = gql`
  query (
    $coinId: ObjectID!
    $userName: [String!]
    $fromDate: Time!
    $toDate: Time!
  ) {
    referralStatisticsByDay(
      coinId: $coinId
      userName: $userName
      fromDate: $fromDate
      toDate: $toDate
    ) {
      userId
      userName
      hashRate
      shares
      sharesOk
      blocks
      reward
      prices
    }
  }
`;

export const GET_REFEREE_SETTINGS = gql`
  query (
    $coinId: ObjectID!
    $userName: [String!]
    $pageNumber: Uint64!
    $pageSize: Uint64!
  ) {
    refereeSettings(
      coinId: $coinId
      userName: $userName
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      id
      name
      percent
      showPercent
      dateAdded
    }
  }
`;

export const GET_REFEREE_INFO = gql`
  query (
    $coinId: ObjectID!
    $active: Boolean
    $pageNumber: Uint64!
    $pageSize: Uint64!
  ) {
    refereeInfo(
      coinId: $coinId
      active: $active
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      id
      name
      activeWorkers
      inactiveWorkers
      hashRate
      active
      lastShare
    }
  }
`;

export const GET_REFERRAL_STATISTICS_TOTAL = gql`
  query {
    referralStatisticsTotal {
      coinId
      totalUsers
      totalWorkers
      hashRate
      blocks
    }
  }
`;

export const CREATE_REFEREE = gql`
  mutation ($refereeName: [String!]!) {
    createReferee(refereeName: $refereeName)
  }
`;

export const UPDATE_REFEREE = gql`
  mutation ($coinId: ObjectID!, $input: [RefereeUpdateInput!]!) {
    updateReferee(coinId: $coinId, input: $input)
  }
`;

export const REMOVE_REFEREE = gql`
  mutation ($refereeIds: [ObjectID!]!) {
    removeReferee(refereeIds: $refereeIds)
  }
`;
