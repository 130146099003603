import customStaticRanges from 'utils/custom-static-ranges';
import { bigIntToPercent, valuesToPercent } from 'utils/helpers/converters';

const handleSelectOne = (state, action) => {
  const selectedIndex = state.selectedItemsNames.findIndex((element) => {
    return element.value === action.item.value;
  });
  let newItemsNames = [];

  if (selectedIndex === -1) {
    newItemsNames = newItemsNames.concat(state.selectedItemsNames, action.item);
  } else if (selectedIndex === 0) {
    newItemsNames = newItemsNames.concat(state.selectedItemsNames.slice(1));
  } else if (selectedIndex > 0) {
    newItemsNames = newItemsNames.concat(
      state.selectedItemsNames.slice(0, selectedIndex),
      state.selectedItemsNames.slice(selectedIndex + 1)
    );
  }

  return newItemsNames;
};

const setUsers = (action) => {
  return action.users.map((item) => ({
    ...item,
    percent: bigIntToPercent(item.percent),
    showPercent: bigIntToPercent(item.showPercent)
  }));
};

const removeUsers = (state, action) => {
  return state.users.filter(
    (item) => !action.ids.some((key) => item.id === key)
  );
};

const updateUsers = (state, action) => {
  return state.users.map((item) => {
    return action.values.users.some((user) => user.value === item.id)
      ? {
          ...item,
          percent: valuesToPercent(action.values.percent),
          showPercent: valuesToPercent(action.values.showPercent)
        }
      : item;
  });
};

const handleSelectAll = (state) => {
  return state.users.map((item) => ({
    label: item.name,
    value: item.id
  }));
};

export const referralsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA': {
      return {
        ...state,
        users: setUsers(action)
      };
    }
    case 'DELETE_DATA': {
      return {
        ...state,
        users: removeUsers(state, action),
        selectedItemsNames: []
      };
    }
    case 'UPDATE_DATA': {
      return {
        ...state,
        users: updateUsers(state, action)
      };
    }
    case 'SET_ACTION': {
      return {
        ...state,
        action: action.value,
        selectedItemsNames: []
      };
    }
    case 'SET_SELECTED_ITEMS_NAMES': {
      return {
        ...state,
        selectedItemsNames: [...action.names]
      };
    }
    case 'SELECT_ONE_NAME': {
      return {
        ...state,
        selectedItemsNames: handleSelectOne(state, action)
      };
    }
    case 'SELECT_ALL_NAMES': {
      return {
        ...state,
        selectedItemsNames: handleSelectAll(state)
      };
    }
    case 'SET_TOGGLE_DATE_RANGE': {
      const activeStaticDateRange = customStaticRanges.find(
        (item) => item.tag === action.activeDateRange
      );
      return {
        ...state,
        activeStateRange: {
          startDate: activeStaticDateRange.range().startDate,
          endDate: activeStaticDateRange.range().endDate
        }
      };
    }
    case 'SET_FILTERS': {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filter
        }
      };
    }
    case 'SET_ACTIVE_COIN': {
      return {
        ...state,
        activeCoin: action.coin
      };
    }
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
