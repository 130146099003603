import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Button } from 'components/common/button/button';
import { useReferrals } from 'contexts/referrals/referrals.context';

import AddForm from './forms/add-form';
import ChangeForm from './forms/change-form';
import DeleteForm from './forms/delete-form';

import {
  StyledActions,
  StyledActionsList,
  StyledActionItem
} from './actions.style';

const actionsButtons = [
  {
    name: 'add',
    text: 'pageReferrals.actions.add.text'
  },
  {
    name: 'change',
    text: 'pageReferrals.actions.change.text'
  },
  {
    name: 'delete',
    text: 'pageReferrals.actions.delete.text'
  }
];

const getActiveForm = (form) => {
  switch (form) {
    case 'add':
      return <AddForm />;
    case 'change':
      return <ChangeForm />;
    case 'delete':
      return <DeleteForm />;
    default:
      return <p>No component match</p>;
  }
};

const Actions = () => {
  const { t } = useTranslation();
  const { action, setAction } = useReferrals();

  return (
    <StyledActions>
      <StyledActionsList>
        {actionsButtons.map((item) => {
          const { name, text } = item;

          return (
            <StyledActionItem key={name}>
              <Button
                className={cx('upper', { active: action === name })}
                onClick={action === name ? undefined : () => setAction(name)}
                variant="outlined"
                fullwidth
              >
                {t(text)}
              </Button>
            </StyledActionItem>
          );
        })}
      </StyledActionsList>
      {getActiveForm(action)}
    </StyledActions>
  );
};

export default Actions;
