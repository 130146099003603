import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { useMutation } from '@apollo/client';

import { useReferrals } from 'contexts/referrals/referrals.context';
import { REMOVE_REFEREE } from 'graphql/referee.query';
import { useNotify } from 'contexts/notification.context';

import { Button } from 'components/common/button/button';
import { StyledForm, StyledFormWrapper, StyledLabel } from '../actions.style';
import DataAction from '../data-action';
import customStyles from '../custom-styles';

const DeleteForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setMessage } = useNotify();
  const { users, selectedItemsNames, deleteUsers, setSelectedItemsNames } =
    useReferrals();
  const [removeReferee, { loading }] = useMutation(REMOVE_REFEREE);

  const submitHandler = async (
    values,
    { touched, validateForm, resetForm, setTouched, setSubmitting }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      const refereeIds = values.users.map((item) => item.value);
      try {
        const result = await removeReferee({
          variables: {
            refereeIds
          },
          context: { clientName: 'private' }
        });
        if (result?.data?.removeReferee) {
          await deleteUsers(refereeIds);
          setMessage('pageReferrals.notifications.removeRefereeSuccess', {
            status: 'success'
          });
        }
        resetForm();
      } catch (err) {
        resetForm();
      }
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <StyledFormWrapper>
      <StyledLabel>
        {t('pageReferrals.actions.delete.text')}
        <span>{t('pageReferrals.actions.delete.help')}</span>
      </StyledLabel>

      <Formik
        validateOnBlur
        initialValues={{
          users: []
        }}
        validationSchema={yup.object().shape({
          users: yup
            .array()
            .min(1)
            .of(
              yup.object().shape({
                label: yup.string(),
                value: yup.string()
              })
            )
            .required(t('pageReferrals.formFields.userRequired'))
        })}
        onSubmit={submitHandler}
      >
        {(formik) => (
          <Form noValidate>
            <DataAction items={selectedItemsNames}>
              <StyledForm className="flex-mode">
                <Select
                  isMulti
                  required
                  styles={customStyles(theme)}
                  name="users"
                  onChange={(selectedOption) => {
                    setSelectedItemsNames(selectedOption);
                  }}
                  value={selectedItemsNames}
                  options={users.map((item) => ({
                    value: item.id,
                    label: item.name
                  }))}
                />
                <Button
                  size="middle"
                  variant="success"
                  loading={loading}
                  disabled={!formik.isValid}
                  type="submit"
                >
                  {t('pageReferrals.actions.delete.buttonText')}
                </Button>
              </StyledForm>
            </DataAction>
          </Form>
        )}
      </Formik>
    </StyledFormWrapper>
  );
};

export default DeleteForm;
