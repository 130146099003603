import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledActions = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const StyledActionsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  align-items: center;

  @media ${themeGet('mediaQueries.bigDesktopUp')} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${themeGet('mediaQueries.mobileOnly')} {
    grid-template-columns: 1fr;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledActionItem = styled.li`
  list-style: none;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledForm = styled.div`
  position: relative;

  &.flex-mode {
    display: flex;
    gap: 20px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    > div {
      flex-grow: 1;

      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }

  &.grid-mode {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: flex-end;

    @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
      grid-template-columns: 66% 1.6fr 1.3fr 0.8fr;
    }

    @media ${themeGet('mediaQueries.mobileOnly')} {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledElement = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: ${themeGet('fontSizes.xs')}px;
  font-weight: ${themeGet('fontWeights.bold')};

  span {
    margin-left: 8px;
    text-transform: none;
    font-weight: ${themeGet('fontWeights.regular')};
    color: ${themeGet('colors.text.regular')};

    @media ${themeGet('mediaQueries.mobileOnly')} {
      display: block;
      margin-left: 0;
    }
  }
`;
